import React from 'react';
import classes from './KycParam.module.css';
import ImageInput from "../../../../../../components/ImageInput/ImageInput";
import {useTranslation} from "react-i18next";
import Sample from "../Sample/Sample";

const KycParam = ({data, documents, setDocuments}) => {
    const {t} = useTranslation();

    return (
        <div className={`column my-2 width-100`}>

            <div className={`${classes.container} width-100 rounded-8 column jc-between ai-center mb-2`}>
                <div className={`${classes.header} width-100 row jc-between ai-center`}>
                    <span className={`font-weight-bold fs-02`}>{data?.name_fa}</span>
                </div>
                <div className={`${classes.content} width-100 row jc-between ai-center`}>
                    {data?.exp}
                </div>
            </div>

            {data?.sample_image && <Sample title="sampleImg" data={data?.sample_image}/>}
            {data?.sample_text && <Sample title="sampleText" data={data?.sample_text}/>}

            <ImageInput
                zoneCustomClass={classes.zoneBox}
                title={data?.name_fa}
                accept={data?.mimeType}
                maxSize={data?.maxSize}
                minSize={data?.minSize}
                onchange={(url) => setDocuments({...documents, [data?.name_en]: url})}
            />
        </div>
    );
};

export default KycParam;
