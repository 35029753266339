import React from 'react';
import useGlobal from "../../../../../../Hooks/useGlobal";
import {images} from "../../../../../../assets/images/images";

const SampleImg = ({}) => {

    const {global} = useGlobal();

    return (
        <div className={`flex jc-center ai-center height-100`}>
            <img src={global?.kyc_sampleImg ? global?.kyc_sampleImg : images.file} className={`${global?.kyc_sampleImg ? "width-100" : "width-15"} rounded-8`} alt="sample-image" />
        </div>
    );
};

export default SampleImg;
