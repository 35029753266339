import React, {useEffect, useState} from 'react';
import classes from './OnChainDeposit.module.css';
import TextInput from "../../../../../../components/TextInput/TextInput";
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";
import useAuth from "../../../../../../Hooks/useAuth";
import {useGetUserWallet} from "../../../../../../query";
import {BN} from "../../../../../../utils/utils";
import DepositAddress from "../DepositAddress/DepositAddress";
import OnChainDepositAddress from "../OnChainDepositAddress/OnChainDepositAddress";

const OnChainDeposit = () => {

    const {t} = useTranslation();
    const {global, setGlobal} = useGlobal();
    const {auth} = useAuth();
    const gateway = global?.selected_deposit_network

    const [input, setInput] = useState({
        network: {value: "", error: []},
        address: {value: "", error: []},
        txPassword: {value: "", error: []},
    });

    const {data: userWalletData, isLoading: userWalletIsLoading, error: userWalletError, refetch:refetchUserWallet} = useGetUserWallet()


    useEffect(()=>{
        let newInputData = {...input}
        if (gateway) {
            newInputData = {...newInputData,
                network : { value: gateway?.chain?.toUpperCase() + " - " + gateway?.implementationSymbol, error: []},
            }
        }
        else {
            newInputData = {...newInputData,
                network : { value: "", error: []},
            }
        }
        setInput(newInputData)
    },[global?.selectType])


    const precision = global?.currencies[gateway?.currencySymbol]?.precision
    const freeWallet = !userWalletIsLoading && userWalletData[gateway?.currencySymbol]?.balance || 0
    const minDeposit = gateway?.depositMin
    const maxDeposit = gateway?.depositMax
    const DepositFee = gateway?.withdrawFee
    /*const withdrawEnabled = !isLoading && data?.currencyImpData?.implementations[0]?.withdrawEnabled*/
    const depositEnabled = gateway?.depositAllowed


    return (
        <>

            <TextInput
                value={input.network.value}
                // value={t('currency.'+ input.asset.value)}
                type="text"
                label={t('network')}
                //placeholder={t('Login.mobilePh')}
                data-name="network"
                data-type="input"
                data-min={2}
                //maxLength="10"
                /*onchange={(e) => inputHandler(e)}*/
                alerts={input.network.error}
                inputClass={`width-85 my-1`}
                select={true}
                readOnly={true}
                selectType="deposit_network"
            />

            {
                gateway && <>
                    <div className={`width-86 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 my-1 position-relative `}>
                        <span className={`width-40`}>{t("balance")}</span>
                        <div className={`width-60 row jc-end ai-center`}>
                            <span className={`fs-02 font-weight-bold`}>{new BN(freeWallet)?.decimalPlaces(precision).toFormat()}</span>
                            <span className={`${classes.space}`}>{global?.currencies[gateway?.currencySymbol]?.alias}</span>
                        </div>
                    </div>
                    <div className={`width-86 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 my-1 position-relative `}>
                        <span className={`width-40`}>{t("Deposit.minDeposit")}</span>
                        <div className={`width-60 row jc-end ai-center`}>
                            <span className={`fs-02 font-weight-bold`}>{new BN(minDeposit)?.decimalPlaces(precision).toFormat()}</span>
                            <span className={`${classes.space}`}>{global?.currencies[gateway?.currencySymbol]?.alias}</span>
                        </div>
                    </div>
                    <div className={`width-86 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 my-1 position-relative `}>
                        <span className={`width-40`}>{t("Deposit.maxDeposit")}</span>
                        <div className={`width-60 row jc-end ai-center`}>
                            <span className={`fs-02 font-weight-bold`}>{new BN(maxDeposit)?.decimalPlaces(precision).toFormat()}</span>
                            <span className={`${classes.space}`}>{global?.currencies[gateway?.currencySymbol]?.alias}</span>
                        </div>
                    </div>

                   <OnChainDepositAddress/>

                </>
            }

        </>
    );
};

export default OnChainDeposit;
