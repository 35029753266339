export const Home = "/";
export const Login = "/login";
export const Password = "/password";
export const Otp = "/otp";
export const Register = "/register";
export const Transfer = "/transfer";
export const Exchange = "/exchange";
export const Deposit = "/deposit";
export const Withdrawal = "/withdrawal";
export const Transaction = "/transaction";
export const Authentication = "/authentication";

export const Guide = "/authentication/guide";

export const UploadImages = "/authentication/upload-images";
export const UploadDocuments = "/authentication/upload-documents";

export const KYCStatus = "/authentication/kyc-status";

export const Chat = "/chat";
export const Accounts = "/accounts";
export const UpdateProfile = "/update-profile";
export const TransactionPassword = "/transaction-password";
/*export const TransactionsHistory = "/transaction-history";*/
export const ForgetPassword = "/forget-password";
export const ContactUs = "/contact-us";
export const Rules = "/rules";
export const Markets = "/markets/";


export const History = "/history";
export const HistoryRelative = "/history";

export const TransactionsHistory = "/history/transactions";
export const TransactionsHistoryRelative = "/transactions";

export const DepositHistory = "/history/deposit";
export const DepositHistoryRelative = "/deposit";

export const WithdrawHistory = "/history/withdraw";
export const WithdrawHistoryRelative = "/withdraw";

export const SwapHistory = "/history/swap";
export const SwapHistoryRelative = "/swap";
