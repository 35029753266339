import React, {useEffect} from 'react';
import * as RoutesName from "../../../../Routes/routes";
import {useNavigate} from "react-router-dom";
import {useGetKycStatus} from "../../../../query";

const Authentication = () => {

    const navigate = useNavigate();

    const {data, isLoading, error} = useGetKycStatus()

    useEffect(() => {

        if (!isLoading) {
            if (data?.kyc_status === "NoKYC") {
                return navigate(RoutesName.Guide)
            }
            else return navigate(RoutesName.KYCStatus)
        }

    }, [data?.kyc_status])


};

export default Authentication;
