import React from 'react';
import classes from './ContactUsData.module.css';
import useGlobal from "../../../../../../Hooks/useGlobal";
import QRCode from "react-qr-code";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";
import {MdOutlineContentCopy} from "react-icons/md";
import toast from "react-hot-toast";
import {FiExternalLink} from "react-icons/fi";
import MobileComp from "../../../../../../components/MobileComp/MobileComp";
const ContactUsData = () => {

    const {t} = useTranslation();
    const {global} = useGlobal()

    const data = global?.contact_us_data

    const copyToClipboard = (e, value) => {
        e.stopPropagation();
        e.preventDefault();
        navigator.clipboard.writeText(value)
        toast.success(t("copy"));
    }

    return (
        <div className={`width-100 height-100 column jc-between ai-center`}>
            <div className={`row jc-center ai-center ${classes.noteBox} width-86 rounded-8 py-1`}>
                {data?.icon}
                <span className={`${isMobile ? 'mr-2' : 'mr-05' } font-weight-bold fs-02`}>{data?.title}</span>
            </div>

            <span className={`fs-03 font-weight-bold mt-1`}>{data?.type === "whatsapp" ? <MobileComp mobile={data?.value}/> :  data?.value}</span>




            <div className={`column jc-center ai-center mb-1`}>
                <div className={`row jc-center ai-center hover-text cursor-pointer`} onClick={(e) => copyToClipboard(e, data?.value)}>
                    <MdOutlineContentCopy size="1.5rem"/>
                    <span className={`${isMobile ? 'mr-2' : 'mr-05' }`}>{t("Contact.copyValue")}</span>
                </div>
                <div className={`row jc-center ai-center hover-text cursor-pointer`} onClick={(e) => copyToClipboard(e, data?.href)}>
                    <MdOutlineContentCopy size="1.5rem" />
                    <span className={`${isMobile ? 'mr-2' : 'mr-05' }`}>{t("Contact.copyLink")}</span>
                </div>

            </div>


            <QRCode
                value={data?.href}
                bgColor="var(--cardBody)"
                fgColor="var(--textColor)"
                level='L'
                size={150}
            />

            <a href={data?.href} target="_blank" rel="noopener noreferrer" className={`row jc-center ai-center hover-text cursor-pointer mt-1 mb-2`}>
                <FiExternalLink size="1.5rem" />
            </a>
        </div>
    );
};

export default ContactUsData;
