import React from 'react';
import useGlobal from "../../../../../../Hooks/useGlobal";

const SampleText = () => {

    const {global} = useGlobal()
    return (
        <div className={`width-86 m-auto pt-4`}>{global?.kyc_sampleText}</div>
    );
};

export default SampleText;
