import React from 'react';
import classes from './UserBankAccount.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import useGlobal from "../../../../../../Hooks/useGlobal";
import {useGetBankAccounts} from "../../../../../../query";
import Button from "../../../../../../components/Button/Button";
import * as RoutesName from "../../../../../../Routes/routes";
import IbanCard from "../IbanCard/IbanCard";
import UserBankAccountCard from "../UserBankAccountCard/UserBankAccountCard";

const UserBankAccount = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const {global, setGlobal} = useGlobal();


    const transferMethod = global?.selected_withdrawal_network?.transferMethod

    const filter = (transferMethod) => {
        switch (transferMethod) {
            case transferMethod === "Sheba":
                return "iban";
            case transferMethod === "Card2card":
                return "card";
            default:
                return ;
        }
    };

    const {data, isLoading, error, refetch} = useGetBankAccounts()

    /*console.log("data iban", !isLoading && Object?.keys(data)?.length)*/

    const content = () => {

        if (!global?.selected_withdrawal_coin) {
            return <span className={`my-10`}>{t("Deposit.selectNetwork")}</span>
        }


        if (isLoading) {
            return <span className={`my-10`}>{t("loading")}</span>
        }
        if (error) {
            return <span className={`my-10`}>{t("error")}</span>
        }
        /*if (Object?.keys(data)?.length <= 0) {
            return <div className={`my-10 width-100 column jc-center ai-center`}>
                <span className={`mb-3`}>{t("noData")}</span>
                <Button
                    type="button"
                    buttonClass={`${classes.thisButton} width-40 cursor-pointer rounded-100-p mt-3`}
                    buttonTitle={t('LinkedBankAccounts.newAccount')}
                    onClick={()=>navigate(RoutesName.Accounts)}
                />
            </div>
        }*/


        if (transferMethod === "Card2card") {

            if (Object?.keys(data)?.filter((f) => data[f]?.card !== null)?.length <= 0) {
                return <div className={`my-10 width-100 column jc-center ai-center`}>
                    <span className={`mb-3`}>{t("noData")}</span>
                    <Button
                        type="button"
                        buttonClass={`${classes.thisButton} width-40 cursor-pointer rounded-100-p mt-3`}
                        buttonTitle={t('LinkedBankAccounts.newAccount')}
                        onClick={()=>navigate(RoutesName.Accounts)}
                    />
                </div>
            } else return Object.keys(data)
                .filter((f) => data[f]?.card !== null)
                .map(key => <UserBankAccountCard data={data[key]} key={key}/>)
        }



        if (transferMethod === "Sheba") {

            if (Object?.keys(data)?.filter((f) => data[f]?.iban !== null)?.length <= 0) {
                return <div className={`my-10 width-100 column jc-center ai-center`}>
                    <span className={`mb-3`}>{t("noData")}</span>
                    <Button
                        type="button"
                        buttonClass={`${classes.thisButton} width-40 cursor-pointer rounded-100-p mt-3`}
                        buttonTitle={t('LinkedBankAccounts.newAccount')}
                        onClick={()=>navigate(RoutesName.Accounts)}
                    />
                </div>
            }
            else return Object.keys(data)
                .filter((f) => data[f]?.iban !== null)
                .map(key => <UserBankAccountCard data={data[key]} key={key}/>)
        }



    }


    return (
        content()
    );
};

export default UserBankAccount;
