import React, {useEffect, useState} from 'react';
import classes from './Otp.module.css'
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Button from "../../../../components/Button/Button";
import * as RoutesName from "../../../../Routes/routes";
import useAuth from "../../../../Hooks/useAuth";
import {validateOTP} from "../../../../api/auth";
import toast from "react-hot-toast";
import Loading from "../../../../components/Loading/Loading";
import OtpContainer from "../../../../components/OtpContainer/OtpContainer";
import i18next from "i18next";

const Otp = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const {auth, setAuth} = useAuth();

    const [loading, setLoading] = useState(false)

    const [input, setInput] = useState({
        otp: {value: "", error: []},
    });

    useEffect(()=>{
        if (!auth?.mobile) navigate(RoutesName.Login)
    },[auth])

    const submit = async (e) => {

        if (!input.otp.value) {
            return setInput({...input, otp:{...input.otp, error: [`${t("Otp.enterCode")}`] } })
        }

        if (input.otp.value.length < 6) {
            return setInput({...input, otp:{...input.otp, error: [`${t("Otp.otpCodeMin")}`] } })
        }

        setLoading(true)

        validateOTP(auth?.mobile, input?.otp?.value)
            .then(async (res) => {
                setAuth({...auth,
                    InitialToken: res?.data?.data?.access_token,
                })
                navigate(RoutesName.Register)
            }).catch(err => {

                if (i18next.exists('ErrorMessages.'+ err?.response?.data?.message)) {
                    toast.error(t("ErrorMessages."+ err?.response?.data?.message))
                }
                else {
                    toast.error(t("serverError"))
                }
        }).finally(() => {
            setLoading(false)
        })

    }

    const buttonTitleHandler = () => {
        if (loading) return <Loading type="text"/>
        return t('submit')
    }

    return (
        <div className={`${classes.container} height-100 column jc-between ai-center py-3 px-5`}>
            <OtpContainer input={input} setInput={setInput} />
            <Button
                type="button"
                buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-1`}
                buttonTitle={buttonTitleHandler()}
                disabled={loading}
                onClick={()=>submit()}
            />
        </div>
    );
};

export default Otp;
