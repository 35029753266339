import React from 'react';
import classes from "./UserBankAccountCard.module.css"
import {Trans, useTranslation} from "react-i18next";
import useAuth from "../../../../../../Hooks/useAuth";
import useGlobal from "../../../../../../Hooks/useGlobal";
import toast from "react-hot-toast";
import {images} from "../../../../../../assets/images/images";

const UserBankAccountCard = ({data}) => {

    const isAllowed = data?.status === "Approved"


    const {t} = useTranslation();
    const {auth} = useAuth();
    const {global, setGlobal} = useGlobal();

    const statusHandler = (status) => {
        if (status === "Approved") return "text-green"
        return "text-gray"
    }

    const selectedAccountHandler = (account) => {
        if (!isAllowed) {
            return toast.error(t("accountIsNotAllowed"))
        }
        let newGlobal = {...global}
        if (newGlobal) {
            newGlobal = {
                ...newGlobal,
                activeActionSheet: {
                    menu: false,
                    select: false,
                },
                selectType: null,
                ["selected_" + global?.selectType]: account
            }
        }
        else {
            newGlobal = {
                ...newGlobal,
                activeActionSheet: {
                    menu: false,
                    select: false,
                },
                selectType: null,
                ["selected_" + global?.selectType]: null
            }
        }
        setGlobal(newGlobal)
    }

    return (
        <div className={`row jc-between ai-center ${classes.container} mb-2 rounded-8 ${!isAllowed && "text-gray"}`} onClick={()=>selectedAccountHandler(data?.card !== null ? data?.card : data?.iban)}>
            <div className={`${classes.logo} ${!isAllowed && classes.grayFilter} width-26`}
                 style={{backgroundImage: `url("${images[data?.bank]}")`}}
            />
            <div className={`column jc-start width-74`}>
                <div className={`row jc-between`}>
                         <span className={`font-weight-bold`}>
                            <Trans
                                i18nKey="LinkedBankAccounts.bankName"
                                values={{
                                    name: t("IBAN."+ data?.bank),
                                }}
                            />
                        </span>

                    <span className={` fs-0-9 ${statusHandler(data?.status)}`}>
                            {t("LinkedBankAccounts."+ data?.status)}
                        </span>
                </div>

                <div className={`row jc-between ai-center `}>

                    {
                        data?.card !== null && <span className={`direction-ltr`}>{data?.card.match(new RegExp('.{1,4}', 'g')).join(" - ")}</span>
                    }
                    {
                        data?.iban !== null && <span className={`direction-ltr`}>{data?.iban}</span>
                    }

                    {/* <Icon
                        iconName={`icon-copy fs-02 flex cursor-pointer`}
                        iconClass={``}
                        onClick={(e) => copyToClipboard(e, data?.number)}

                    />*/}
                </div>
            </div>
        </div>
    );
};

export default UserBankAccountCard;
