import React, {useState} from 'react';
import classes from './Sample.module.css';
import Icons from "../../../../../../components/Icon/Icons";
import {Trans, useTranslation} from "react-i18next";
import Loading from "../../../../../../components/Loading/Loading";
import useGlobal from "../../../../../../Hooks/useGlobal";

const Sample = ({title, data}) => {


    const {t} = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const {global, setGlobal} = useGlobal();

    const selectClickHandler = () => {
        setGlobal({
            ...global,
            activeActionSheet: {
                menu: false,
                select: true,
            },
            selectType: title,
            ["kyc_" + title]: data
        })
    }

    return (
        <div className={`${classes.container} width-100 rounded-8 column mb-2`} onClick={()=>selectClickHandler()}>
            <div className={`row jc-between ai-center `}>
                <span>{t("Authentication."+ title)}</span>
                <Icons
                    iconName={`${isOpen ? 'icon-up-open' : 'icon-down-open'} flex fs-04`}
                    iconClass={`cursor-pointer`}
                />
            </div>
            {isOpen && <div className={`column jc-center ai-center mt-2`}>

                {data?.sample_image ?
                    <img src={`${data?.sample_image}`} className={`${classes.sampleImg}`}/> :
                    <span className={`fs-0-9 py-4`}>{t("noData")}</span>
                }
            </div>}
        </div>

    );
};

export default Sample;
