import React, {useState} from 'react';
import classes from "./AddIban.module.css";
import {Trans, useTranslation} from "react-i18next";
import Icon from "../../../../../../components/Icon/Icons";
import TextInput from "../../../../../../components/TextInput/TextInput";
import {createAccount} from "../../../../../../api/auth";
import toast from "react-hot-toast";
import Button from "../../../../../../components/Button/Button";
import Loading from "../../../../../../components/Loading/Loading";
import {ibanHandler} from "../../../../../../utils/iban";
import {images} from "../../../../../../assets/images/images";
import useAuth from "../../../../../../Hooks/useAuth";
import {useGetBankAccounts} from "../../../../../../query";

const AddIban = () => {

    const {t} = useTranslation();
    const {auth} = useAuth();

    const [loading, setLoading] = useState(false)

    const {refetch} = useGetBankAccounts()


    const [input, setInput] = useState({
        iban: {value: "IR", error: []},
    });

    const currentIban = input?.iban?.value.slice(4,7)

    const inputHandler = (e) => {
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset.min !== undefined && e.target.value.length < e.target.dataset.min) {
            errorMessage.push(<Trans
                i18nKey="Login.minInput"
                values={{
                    name: t("LinkedBankAccounts."+ e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }
        let prevState = {
            ...input,
            [e.target.dataset.name]: {...input[e.target.dataset.name], value: inputVal, error: errorMessage}
        }
        setInput(prevState)
    }
    const isFormValid = () => {
        let inputs = {...input}

        const hasError = Object.values(input).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false

        for (const key in inputs) {
            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [<Trans
                            i18nKey="Login.emptyInput"
                            values={{
                                name: t(key),
                            }}
                        />]
                    }
                }
            }
        }

        setInput(inputs);
        return !isEmpty;
    }

    const submit = async (e) => {
        e.preventDefault();

        if (!isFormValid()) return false;


        setLoading(true)

        const ibanParam = {
            "bank": ibanHandler(currentIban),
            "iban": input.iban.value
        }

        createAccount(ibanParam, auth?.token)
            .then(async (res) => {

                toast.success(<Trans
                    i18nKey="LinkedBankAccounts.success"
                    values={{
                        name: t("LinkedBankAccounts.ibanNumber"),
                    }}
                />)

                setInput({
                    iban: {value: "IR", error: []},
                })
                refetch()

            }).catch(err => {


            toast.error(err?.response?.data?.message)

        }).finally(() => {

            setLoading(false)

        })


    }

    const pasteFromClipboard = (e) => {
        e.preventDefault();
        if (navigator.clipboard !== undefined && navigator.clipboard !== null) {
            navigator.clipboard.readText()
                .then(text => {
                    //console.log('Pasted content: ', text);
                    setInput({...input, iban: {value: text, error: []}})
                    //{toast.success(t('paste'));
                })
                .catch(err => {
                    console.error('Failed to read clipboard contents: ', err);
                });
        }
        else {
            /*toast.error(t('error'))*/
            return false
        };

    }

    const buttonTitleHandler = () => {
        if (loading) return <Loading type="text"/>
        return t('submit')
    }


    return (
        <form onSubmit={(e)=>submit(e)} className={`${classes.container} width-100 column jc-center ai-center`}>

            <div className={`${classes.logo}`}
                 style={{backgroundImage: `url("${images[ibanHandler(currentIban)]}")`}}
            />

            <span className={`mt-1 mb-2 font-weight-bold`}>
                <Trans
                    i18nKey="LinkedBankAccounts.bankName"
                    values={{
                        name: currentIban.length >= 3 ? t("IBAN."+  ibanHandler(currentIban)) : "---",
                    }}
                />
            </span>


            <TextInput
                value={input.iban.value.toUpperCase()}
                label={t('LinkedBankAccounts.ibanNumber')}
                id="iban"
                labelFor="iban"
                //placeholder={t('Login.mobilePh')}
                data-name="iban"
                data-type="input"
                data-min={26}
                maxLength="26"
                onchange={(e) => inputHandler(e)}
                alerts={input.iban.error}
                inputClass={`width-100 mt-1 mb-3`}
                type="text"
                icon={
                    <Icon
                        iconName={`icon-paste-1 fs-03 flex cursor-pointer`}
                        onClick={(e) => pasteFromClipboard(e)}
                    />
                }
            />


            <Button
                type="submit"
                buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-1`}
                buttonTitle={buttonTitleHandler()}
                disabled={loading}
            />



        </form>
    );
};

export default AddIban;
