import React, {useState} from 'react';
import classes from './Content.module.css'
import NavBar from "../../../../../../components/NavBar/NavBar";
import {useTranslation} from "react-i18next";
import LastPrice from "../LastPrice/LastPrice";
import RecentTxHistory from "../RecentTxHistory/RecentTxHistory";
import Wallet from "../Wallet/Wallet";

const Content = () => {

    const {t} = useTranslation();

    const [step, setStep] = useState(1)

    const tab = [
        {id: 1, title: t("rates"), action:  () => setStep(1)},
        {id: 2, title: t("wallet"), action:() => setStep(2)},
        {id: 3, title: t("History.transactions"), action:() => setStep(3)},
    ];

    const content = () => {

        if (step === 1) {
            return <LastPrice/>
        }
        if (step === 2) {
            return <Wallet/>
        }
        if (step === 3) {
            return <RecentTxHistory/>
        }
    }

    return (
        <div className={`${classes.container} column jc-center ai-center`}>

            <NavBar
                tab={tab}
                navClass={`width-86`}
            />

            {content()}

        </div>
    );
};

export default Content;
