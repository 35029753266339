import {HiOutlineMail, HiOutlinePhone} from "react-icons/hi";
import {HiOutlineDevicePhoneMobile} from "react-icons/hi2";
import {PiLinkedinLogo, PiTelegramLogo} from "react-icons/pi";
import {AiOutlineWhatsApp} from "react-icons/ai";
import {RxInstagramLogo, RxTwitterLogo} from "react-icons/rx";
import {RiTwitterXFill} from "react-icons/ri";

export const contactConfig = {
    tel: {
        icon: <HiOutlinePhone  size="1.7rem"/>,
        prefix: 'tel:',
    },
    mobile: {
        icon: <HiOutlineDevicePhoneMobile  size="1.7rem"/>,
        prefix: 'tel:',
    },
    email: {
        icon: <HiOutlineMail size="1.7rem"/>,
        prefix: 'mailto:',
    },
    telegram: {
        icon: <PiTelegramLogo size="1.7rem"/>,
        prefix: 'https://t.me/',
    },
    whatsapp: {
        icon: <AiOutlineWhatsApp  size="1.7rem"/>,
        prefix: 'https://wa.me/',
    },
    instagram: {
        icon: <RxInstagramLogo size="1.7rem"/>,
        prefix: 'https://www.instagram.com/',
    },
    linkedin: {
        icon: <PiLinkedinLogo size="1.7rem"/>,
        prefix: 'https://www.linkedin.com/company/',
    },
    x: {
        icon: <RiTwitterXFill size="1.7rem"/>,
        prefix: 'https://x.com/',
    },
};