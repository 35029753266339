import React from 'react';
import classes from './ContactUs.module.css';
import {useGetContactUs} from "../../../../query/hooks/useGetContactUs";
import {useTranslation} from "react-i18next";
import ScrollBar from "../../../../components/ScrollBar";
import Icons from "../../../../components/Icon/Icons";
import ContactItem from "./Module/ContactItem/ContactItem";
import {contactConfig} from "../../../../utils/contactConfig";

const ContactUs = () => {

    const {t} = useTranslation();

    const {data, isLoading, error} = useGetContactUs();

    const content = () => {
        if (isLoading) return <>
            <div className={`${classes.loading} width-85 my-1`}/>
            <div className={`${classes.loading} width-85 my-1`}/>
            <div className={`${classes.loading} width-85 my-1`}/>
            <div className={`${classes.loading} width-85 my-1`}/>
        </>
        if (error) return <>
            <span className={`my-10`}>{t("error")}</span>
        </>
        if (data?.length <= 0 || data === undefined || data === null) {
            return <span className={`my-10`}>{t("noData")}</span>
        }
        return <>



            <span className={`width-100 font-weight-bold fs-02 mb-1`}>{t("Contact.waysOfCommunication")}</span>

            {data?.contacts
                .filter(f => f.active === "1")
                .map((contact, index) => (
                <ContactItem
                    key={index}
                    contact={contact}
                    config={contactConfig[contact.type]}
                />
            ))}

            { data?.tel1 && <a href={`tel:${data?.tel1}`} className={`rounded-8 row jc-between ai-start ${classes.noteBox} cursor-pointer width-100 px-7 py-1 mt-1 mb-1`}>
                <div className={`row`}>
                    <Icons
                        iconName="icon-phone-1  fs-04"
                        iconClass={``}
                    />
                    <span className={`mr-1`}>{t("Contact.phone")}</span>
                </div>
                <span>{data?.tel1}</span>
            </a>}
            { data?.tel2 && <a href={`tel:${data?.tel2}`} className={`rounded-8 row jc-between ai-start ${classes.noteBox} cursor-pointer width-100 px-7 py-1 mt-1 mb-1`}>
                <div className={`row`}>
                    <Icons
                        iconName="icon-phone-1  fs-04"
                        iconClass={``}
                    />
                    <span className={`mr-1`}>{t("Contact.phone")}</span>
                </div>
                <span>{data?.tel2}</span>
            </a>}
            { data?.mobile && <a href={`tel:${data?.mobile}`} className={`rounded-8 row jc-between ai-start ${classes.noteBox} cursor-pointer width-100 px-7 py-1 mt-1 mb-1`}>
                <div className={`row`}>
                    <Icons
                        iconName="icon-mobile  fs-04"
                        iconClass={``}
                    />
                    <span className={`mr-1`}>{t("Contact.mobile")}</span>
                </div>
                <span>{data?.mobile}</span>
            </a>}


            { data?.email && <a href={`mailto:${data?.email}`} className={`rounded-8 row jc-between ai-start ${classes.noteBox} cursor-pointer width-100 px-7 py-1 mt-1 mb-1`}>
                <div className={`row`}>
                    <Icons
                        iconName="icon-email  fs-04"
                        iconClass={``}
                    />
                    <span className={`mr-1`}>{t("Contact.email")}</span>
                </div>
                <span>{data?.email}</span>
            </a>}

            <span className={`width-100 font-weight-bold fs-02 mb-1 mt-2`}>{t("Contact.address")}</span>




            {/*href={`https://maps.google.com/?ll=latitude,longitude`}*/}



            { data?.address && <div className={`rounded-8 column jc-between ai-start ${classes.noteBox} cursor-pointer width-100 px-7 py-1 mt-1 mb-1`}>
                <div className={`row width-100`}>
                    <Icons
                        iconName="icon-location  fs-04"
                        iconClass={``}
                    />
                    <span className={`mr-1`}>{t("Contact.address")}</span>
                </div>
                <span>{data?.address}</span>
            </div>}

            { data?.postal_code && <div className={`rounded-8 row jc-between ai-start ${classes.noteBox} cursor-pointer width-100 px-7 py-1 mt-1 mb-1`}>
                <div className={`row`}>
                    <Icons
                        iconName="icon-postalcode  fs-04"
                        iconClass={``}
                    />
                    <span className={`mr-1`}>{t("Contact.postalCode")}</span>
                </div>
                <span>{data?.postal_code}</span>
            </div>}










        </>
    }


    return (
        <ScrollBar>
            <div className={`${classes.container} width-86 column jc-start ai-center py-3 px-5 m-auto`}>
                {content()}
            </div>
        </ScrollBar>
    );
};

export default ContactUs;
