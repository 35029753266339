import React from 'react';
import classes from './GatewaysCard.module.css';
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";
import toast from "react-hot-toast";

const GatewaysCard = ({data, type}) => {

    const isOnChain = data?.type === "OnChain"

    const isAllowed = ((type === "deposit_network" && data?.depositAllowed) && (type === "deposit_network" && (data?.isActive))) || ((type === "withdrawal_network" && data?.withdrawAllowed) && (type === "withdrawal_network" && (data?.isActive)))
    const {t} = useTranslation();

    const {global, setGlobal} = useGlobal();

    const selectedCoinHandler = (data) => {
        if (!isAllowed) {
            return toast.error(t("networkIsNotAllowed"))
        }
        let newGlobal = {...global}
        if (newGlobal) {
            newGlobal = {
                ...newGlobal,
                activeActionSheet: {
                    menu: false,
                    select: false,
                },
                selectType: null,
                selected_OffChainWithdraw_address: global?.selected_withdrawal_network?.transferMethod === data?.transferMethod ? global?.selected_OffChainWithdraw_address : null,
                ["selected_" + global?.selectType]: data
            }
        }
        else {
            newGlobal = {
                ...newGlobal,
                activeActionSheet: {
                    menu: false,
                    select: false,
                },
                selectType: null,
                ["selected_" + global?.selectType]: null
            }
        }
        setGlobal(newGlobal)
    }


    return (
        <>
            { isOnChain && <div className={`${classes.asset} ${!isAllowed && "text-gray"} width-90 row jc-between ai-center py-1 px-3 rounded-8`} onClick={()=>selectedCoinHandler(data)}>
                {data?.tokenName && <span>{data?.tokenName}</span>}
                <span>{data?.chain?.toUpperCase()}</span>
                <span>{data?.implementationSymbol}</span>
            </div> }
            { !isOnChain && <div className={`${classes.asset} ${!isAllowed && "text-gray"} width-90 row jc-center ai-center py-1 px-3 rounded-8`} onClick={()=>selectedCoinHandler(data)}>
                <span>{t("TransferMethod." + data?.transferMethod)}</span>
            </div> }

        </>
    );
};

export default GatewaysCard;
