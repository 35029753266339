import React from 'react';
import classes from './HistoryCategory.module.css';
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import useGlobal from "../../../../../../Hooks/useGlobal";
import TransactionCategoryCard from "../TransactionCategoryCard/TransactionCategoryCard";
import HistoryCategoryCard from "../HistoryCategoryCard/HistoryCategoryCard";

const HistoryCategory = ({all}) => {

    const {t} = useTranslation();
    const location = useLocation()
    const pathname = location?.pathname

    const {global, setGlobal} = useGlobal();

    const transactionsCategories = ['SWAP', 'DEPOSIT', 'WITHDRAW', 'SYSTEM'];

    const showAllHandler = () => {
        let newGlobal = {...global}
        newGlobal = {
            ...newGlobal,
            activeActionSheet: {
                menu: false,
                select: false,
            },
            selectType: null,
            selected_history_category: null,
        }
        setGlobal(newGlobal)
    }

    const content = () => {
        return <>
            {all && <div className={`${classes.asset} row jc-between ai-center py-1 my-2 px-3 rounded-8`}  onClick={()=>showAllHandler()}>
                <span>{t("all")}</span>
            </div>}
            {
                pathname?.includes("transactions") && transactionsCategories.map(data => <HistoryCategoryCard data={data}/>)
            }
        </>
    }

    return (
        content()
    );
};

export default HistoryCategory;
