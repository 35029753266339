import React from 'react';
import classes from './ContactItem.module.css';
import {isMobile} from "react-device-detect";
import MobileComp from "../../../../../../components/MobileComp/MobileComp";
import {RiQrScan2Line} from "react-icons/ri";
import {MdOutlineContentCopy} from "react-icons/md";
import {FiExternalLink} from "react-icons/fi";
import useGlobal from "../../../../../../Hooks/useGlobal";


const ContactItem = ({ config, contact  }) => {


    const {global, setGlobal} = useGlobal();

    const selectClickHandler = () => {

        const mergedObj = { ...config, ...contact };
        mergedObj.href = href;

        setGlobal({
            ...global,
            activeActionSheet: {
                menu: false,
                select: true,
            },
            selectType: "contact_us",
            "contact_us_data": mergedObj
        })
    }

    if (!config) return null;

    const type = contact.type
    const title = contact.title
    const value = contact.value
    const href = `${config.prefix}${value}`;

    return (
        /*<div className="contact-item py-5">
            {config.icon}

            <QRCode
                value={href}
                bgColor="var(--cardBody)"
                fgColor="var(--textColor)"
                level='L'
                size={130}
            />

            <a href={href} target="_blank" rel="noopener noreferrer">
                {title}: {value}
            </a>
        </div>*/


    <div className={`rounded-8 row jc-between ai-center ${classes.noteBox} width-100 px-7 py-1 mt-1 mb-1`}>
        <div className={`row jc-center ai-center`}>
            {config.icon}
            <span className={`${isMobile ? 'mr-3' : 'mr-1'} ${classes.name}`}>{title}</span>
        </div>


        <div className={`row jc-center ai-center`}>

            <a href={href} target="_blank" rel="noopener noreferrer" className={`flex jc-center ai-center cursor-pointer hover-text`}>
                {type === "whatsapp" ? <MobileComp mobile={value}/> :  value}
            </a>

            <RiQrScan2Line size="1.7rem" className={`flex jc-center ai-center cursor-pointer hover-text ${isMobile ? 'mr-3' : 'mr-1' } `} onClick={()=>selectClickHandler()}/>

            {/*<FiExternalLink size="1.7rem" className={`flex jc-center ai-center cursor-pointer hover-text`} onClick={()=>console.log("click")}/>*/}

            {/*<span className={`flex jc-center ai-center cursor-pointer hover-text`}>

                <MdOutlineContentCopy size="1.7rem"/>
            </span>*/}
        </div>
    </div>
    );
};

export default ContactItem;
