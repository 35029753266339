import React from 'react';
import classes from './TokenExpired.module.css';
import {Trans, useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import useGlobal from "../../Hooks/useGlobal";
import {images} from "../../assets/images/images";
import Button from "../Button/Button";
import useAuth from "../../Hooks/useAuth";
import MobileComp from "../MobileComp/MobileComp";
import * as RoutesName from "../../Routes/routes";


const TokenExpired = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const {auth, setAuth} = useAuth();
    const {global, setGlobal} = useGlobal();

    return (
        <div className={`${classes.container} width-100 column jc-center ai-center`}>
            <img src={images.user} className={`${classes.logo}`} alt=""/>

            <span className={`my-6 font-weight-bold fs-01 text-red`}>{t("tokenExpired")}</span>

            <Button
                type="button"
                buttonClass={`${classes.thisButton} ${classes.background} cursor-pointer rounded-100-p my-1`}
                buttonTitle={<Trans
                    i18nKey="loginToAccount"
                    components={{
                        MobileComp: <MobileComp mobile={auth?.mobile}/>
                    }}
                />}
                onClick={()=> {
                    localStorage.clear()
                    setAuth({...auth,
                        mobile: auth?.mobile,
                        token: null,
                        InitialToken: null,
                        isExpired: false,
                    })
                    setGlobal({
                        ...global,
                        hideUserBalance: true,
                        activeActionSheet: {
                            menu: false,
                            select: false,
                        },
                        selectType: null,
                    })
                    navigate(RoutesName.Password)
                }}
            />

            <Button
                type="button"
                buttonClass={`${classes.thisButton} ${classes.border} cursor-pointer rounded-100-p my-1`}
                buttonTitle={t("loginToAnotherAccount")}
                onClick={()=> {
                    localStorage.clear()
                    setAuth({
                        ...auth,
                        mobile: null,
                        token: null,
                        InitialToken: null,
                        isExpired: false,
                    })
                    setGlobal({
                        ...global,
                        hideUserBalance: true,
                        activeActionSheet: {
                            menu: false,
                            select: false,
                        },
                        selectType: null,
                    })
                    navigate(RoutesName.Login)
                    }
                }
            />

        </div>
    );
};

export default TokenExpired;
