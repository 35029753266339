import React, {useEffect, useState} from 'react';
import classes from "./OffChainDeposit.module.css"
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";
import useAuth from "../../../../../../Hooks/useAuth";
import {useGetUserWallet} from "../../../../../../query";
import TextInput from "../../../../../../components/TextInput/TextInput";

const OffChainDeposit = () => {

    const {t} = useTranslation();
    const {global, setGlobal} = useGlobal();
    const {auth} = useAuth();
    const gateway = global?.selected_deposit_network

    const [loading, setLoading] = useState(false)

    const [input, setInput] = useState({
        transferMethod: {value: "", error: []},
    });

    const {data: userWalletData, isLoading: userWalletIsLoading, error: userWalletError, refetch:refetchUserWallet} = useGetUserWallet()

    useEffect(()=>{
        let newInputData = {...input}
        if (gateway) {
            newInputData = {...newInputData,
                transferMethod : { value: gateway?.transferMethod, error: []},
            }
        }
        setInput(newInputData)
    },[global?.selectType])

    const precision = global?.currencies[gateway?.currencySymbol]?.precision
    const freeWallet = !userWalletIsLoading && userWalletData[gateway?.currencySymbol]?.balance || 0

    const minDeposit = gateway?.depositMin
    const maxDeposit = gateway?.depositMax
    const DepositFee = gateway?.withdrawFee
    /*const withdrawEnabled = !isLoading && data?.currencyImpData?.implementations[0]?.withdrawEnabled*/
    const depositEnabled = gateway?.depositAllowed

    return (
        <>
            <TextInput
                value={input.transferMethod.value ? t("TransferMethod." + input.transferMethod.value) : input.transferMethod.value}
                // value={t('currency.'+ input.asset.value)}
                type="text"
                label={t('transferMethod')}
                //placeholder={t('Login.mobilePh')}
                data-name="transferMethod"
                data-type="input"
                data-min={2}
                //maxLength="10"
                /*onchange={(e) => inputHandler(e)}*/
                alerts={input.transferMethod.error}
                inputClass={`width-85 my-1`}
                select={true}
                readOnly={true}
                selectType="deposit_network"
            />

            {
                input?.transferMethod?.value && <span className={`my-10 font-weight-bold text-orange`}>{t("soon")}</span>
            }


        </>
    );
};

export default OffChainDeposit;
