import React, {useState} from 'react';
import classes from './WithdrawHistoryTable.module.css'
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";
import toast from "react-hot-toast";
import Date from "../../../../../../components/Date/Date";
import moment from "moment-jalaali";
import {printTransferMethod, ratePrint, shortenHash} from "../../../../../../utils/utils";
import Icons from "../../../../../../components/Icon/Icons";
import {useGetWithdrawHistory} from "../../../../../../query";
import Loading from "../../../../../../components/Loading/Loading";
import {cancelWithdrawReq} from "../../../../../../api/exchange";
import Button from "../../../../../../components/Button/Button";
import useAuth from "../../../../../../Hooks/useAuth";
import i18next from "i18next";

const WithdrawHistoryTable = ({data, query}) => {

    const {t} = useTranslation();

    const {global} = useGlobal();
    const {auth} = useAuth();

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const {refetch} = useGetWithdrawHistory(query);

    const copyToClipboard = (e, value) => {
        e.stopPropagation();
        e.preventDefault();
        navigator.clipboard.writeText(value)
        toast.success(t("copy"));
    }


    const cancelWithdrawReqButtonTextHandler = () => {
        if (isLoading) return <Loading type="text"/>
        return t('History.cancelWithdrawReq')
    }

    const cancelWithdrawReqFunc = (withdrawId) => {

        if (isLoading) return
        setIsLoading(true)

        cancelWithdrawReq(withdrawId, auth?.token)
            .then(() => {
                toast.success(t('History.cancelWithdrawReqSuccess'));
                 refetch()
            })
            .catch((err) => {
                if (i18next.exists('ErrorMessages.'+ err?.response?.data?.message)) {
                    toast.error(t("ErrorMessages."+ err?.response?.data?.message))
                }
                else {
                    toast.error(t("serverError"))
                }
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <div className={`width-90 py-2 columns jc-between ai-center ${classes.container}`} >

            <div className={`width-100 column jc-between ai-center ${classes.rectangle}`} onClick={()=>setIsOpen(prevState => !prevState)}>

                <div className={`width-100 row jc-between ai-center`}>
                    <div className={`row`}>
                        <span><Date date={data?.createDate}/></span>
                        <span className={`${classes.spacing}`}/>
                        <span className={``}>{moment.utc(data?.createDate).local().format("HH:mm:ss")}</span>
                    </div>

                    <span className={`row jc-center ai-center`}>{t("HistoryStatus."+ data?.status)}</span>

                    {/*<span className={`row jc-center ai-center`}>{moment(data?.date).format("HH:mm:ss")}</span>*/}
                </div>

                <div className={`width-100 row jc-between ai-center`}>
                    <span>{printTransferMethod(t, data?.destNetwork)}</span>
                    <div className={`direction-ltr row text-red`} style={{alignItems:"baseline"}}>
                        <span className={`fs-03`}>{ ratePrint(data?.amount, global?.currencies[data?.currency]?.precision)}</span>
                        <span className={`${classes.spacing}`}/>
                        <span>{data?.currency}</span>
                    </div>
                </div>
                <div className={`width-100 row jc-between ai-center`}>
                    <span>{t("History.type")}</span>
                    <span>{t("HistoryType."+ data?.withdrawType)}</span>
                </div>

            </div>

            {isOpen && <div className={`width-100 column jc-start ai-start mt-2 ${classes.rectangle}`}>

                <div className={`width-100 row jc-between ai-center`}>
                    <span>{t("History.lastUpdateDate")}</span>
                    <span>
                        {
                            data?.lastUpdateDate ? <>
                                <span className={``}>{moment.utc(data?.lastUpdateDate).local().format("HH:mm:ss")} - </span>
                                <Date date={data?.lastUpdateDate}/>
                            </> : "- - -"
                        }
                    </span>
                </div>

                <div className={`width-100 row jc-between ai-center`}>
                    <span>{t("History.appliedFee")}</span>

                    { data?.appliedFee ? <div className={`direction-ltr row`} style={{alignItems:"baseline"}}>
                        <span className={``}>{ ratePrint(data?.appliedFee, global?.currencies[data?.currency]?.precision)}</span>
                        <span className={`${classes.spacing}`}/>
                        <span className={`fs-0-8`}>{data?.currency}</span>

                    </div> : <span>{"- - -"}</span>}

                </div>



                <div className={`width-100 row jc-between ai-center`}>
                    <span>{t("History.destAddress")}</span>
                    <span>{data?.destAddress ?
                        <div className={`row`}>
                            <span
                                data-html={true}
                                data-place="bottom"
                                data-effect="float"
                                data-tooltip-place="top"
                                data-tooltip-id="polban-tooltip"
                                data-tooltip-float={true}
                                data-tooltip-html={`<span class="column jc-between">${data?.destAddress}</span>`}
                                className={`${classes.name} direction-ltr`}
                            >{shortenHash(data?.destAddress) }</span>
                            <Icons
                                iconName="icon-copy fs-02"
                                iconClass={`cursor-pointer mr-025 hover-text`}
                                onClick={(e) => copyToClipboard(e, data?.destAddress)}
                            />

                        </div>
                        : "- - -"}</span>

                </div>

                <div className={`width-100 row jc-between ai-center`}>
                    <span>{t("History.transactionRef")}</span>
                    <span>{data?.destTransactionRef ?
                        <div className={`row`}>
                            <span
                                data-html={true}
                                data-place="bottom"
                                data-effect="float"
                                data-tooltip-place="top"
                                data-tooltip-id="polban-tooltip"
                                data-tooltip-float={true}
                                data-tooltip-html={`<span class="column jc-between">${data?.destTransactionRef}</span>`}
                                className={`${classes.name} direction-ltr`}
                            >{shortenHash(data?.destTransactionRef) }</span>
                            <Icons
                                iconName="icon-copy fs-02"
                                iconClass={`cursor-pointer mr-025 hover-text`}
                                onClick={(e) => copyToClipboard(e, data?.destTransactionRef)}
                            />

                        </div>
                        : "- - -"}</span>
                </div>


                <div className={`width-100 column jc-start ai-start mt-05`}>
                    <span>{t("History.statusReason")}</span>
                    <span>
                        {
                            data?.statusReason ? data?.statusReason : "- - -"
                        }
                    </span>
                </div>

                { data?.status === "CREATED" && <div className={`width-100 column jc-start ai-start mt-05`}>
                    <Button
                        buttonClass={`${classes.thisButton} ${isLoading ? "cursor-not-allowed" : "cursor-pointer"}`}
                        buttonTitle={cancelWithdrawReqButtonTextHandler()}
                        type="button"
                        onClick={()=>cancelWithdrawReqFunc(data.withdrawId)}
                    />
                </div> }

            </div>}

        </div>
    );
};

export default WithdrawHistoryTable;
