import React, {useEffect, useRef, useState} from 'react';
import classes from './WithdrawHistory.module.css';
import {Trans, useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";
import {useGetDepositHistory, useGetWithdrawHistory} from "../../../../../../query";
import moment from "moment-jalaali";
import DepositHistoryTable from "../DepositHistoryTable/DepositHistoryTable";
import Date from "../../../../../../components/Date/Date";
import WithdrawHistoryTable from "../WithdrawHistoryTable/WithdrawHistoryTable";
import TextInput from "../../../../../../components/TextInput/TextInput";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import ToggleSwitch from "../../../../../../components/ToggleSwitch/ToggleSwitch";
import Button from "../../../../../../components/Button/Button";



const WithdrawHistory = () => {

    const {t} = useTranslation();

    const {global} = useGlobal();

    const [query, setQuery] = useState({
        "currency": null, // optional
        "startTime": null,
        "endTime": null,
        "ascendingByTime": false,
        "limit": 10,
        "offset": 0
    });

    const {data, isLoading, error} = useGetWithdrawHistory(query);

    useEffect(()=>{

        let newInputData = {...query}

        if (global?.selected_withdraw_history_currency) {
            newInputData.currency = global?.selected_withdraw_history_currency
            newInputData.offset = 0
        }
        else {
            newInputData.currency = null
        }


        if (global?.selected_withdraw_history_size) {
            newInputData.limit = global?.selected_withdraw_history_size
            newInputData.offset = 0
        }
        else {
            newInputData.limit = 10
        }

        setQuery(newInputData)

    },[global?.selectType])

    const scrollRef = useRef(null);
    const isFirst = useRef(true);


    useEffect(() => {
        if (!isFirst.current) scrollRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [data]);

    const pagination = {
        page: (query.offset / query.limit) + 1,
        isLastPage: data?.length < query.limit
    }

    const pageSizeHandler = (e) => {
        setQuery({
            ...query,
            limit: e.value,
            offset: 0
        })
    }

    const firstPage = () => {
        setQuery({
            ...query,
            offset: 0
        })
    }
    const nextPage = () => {
        isFirst.current = false;
        setQuery({
            ...query,
            offset: query.offset + query.limit
        })
    }
    const prevPage = () => {
        setQuery({
            ...query,
            offset: query.offset - query.limit
        })
    }
    const startDateHandler = (dateRange) => {
        const start = dateRange[0]  ? moment.unix(dateRange[0].toUnix()).startOf("day").valueOf() : null;
        const end = dateRange[1]  ? moment.unix(dateRange[1].toUnix()).endOf("day").valueOf() : null;
        setQuery({
            ...query,
            startTime: start,
            endTime: end
        })
    }
    const content = () => {
        /* if (isLoading) {
             return <span className={`my-10`}>{t("loading")}</span>
         }*/
        if (isLoading) {
            return <div className={`width-100 column jc-center ai-center height-100 my-1`}>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-76 rounded-8`}/>
                </div>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-76 rounded-8`}/>
                </div>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-76 rounded-8`}/>
                </div>
            </div>
        }
        if (error) {
            return <span className={`my-10`}>{t("error")}</span>
        }
        if (data?.length <= 0) {
            return <span className={`my-10`}>{t("noData")}</span>
        }
        return data?.map( (c , index) =>
            <WithdrawHistoryTable data={c} key={index} query={query}/>
        )
    }

    const periodTextHandler = () => {
        if (query?.startTime && query?.endTime) return <>
            <span className={`mx-05`}>{t("from")}</span>
            <span><Date date={query?.startTime}/></span>
            <span className={`mx-05`}>{t("until")}</span>
            <span><Date date={query?.endTime}/></span>
        </>
        if (query?.startTime) return <>
            <span className={`mx-05`}>{t("from")}</span>
            <span><Date date={query?.startTime}/></span>
            <span className={`mx-05`}>{t("until")}</span>
            <span><Date date={moment().endOf("day").valueOf()}/></span>
        </>
    }




    return (
        <div className={` width-100 m-auto height-100 rounded-8 pt-05 pb-5 column jc-center ai-center`}>
            <div className={`width-100 py-2 rounded-8 column jc-between ai-center`}>
                <TextInput
                    select={true}
                    placeholder={t('History.currency')}

                    /*options={coinsOptions}*/

                    label={t('History.currency')}
                    type="select"

                    readOnly={true}
                    selectType="history_currency"
                    alerts={[]}

                    /*value={{
                        value: query?.coin,
                        label:  query?.coin ? t('currency.'+ query?.coin) : t('all'),
                    }}*/

                    value={query?.currency ? global?.currencies[query?.currency]?.alias  : t('all')}

                    /*onchange={(e) => setQuery({...query, coin: e.value})}*/
                    inputClass={`width-100 my-1 ${classes.thisInput}`}
                />

                <TextInput
                    select={true}
                    placeholder={t('History.size')}
                    /*options={size?.map(s => {
                        return {label: s, value: s}
                    })}*/
                    label={t('History.size')}
                    type="select"


                    readOnly={true}
                    selectType="history_size"
                    alerts={[]}

                    value={query?.limit}
                    /*onchange={pageSizeHandler}*/
                    inputClass={`width-100 my-1 ${classes.thisInput}`}
                />


                <TextInput
                    datePicker={true}
                    plugins={[
                        <DatePanel position="bottom"/>
                    ]}
                    label={t('History.period')}
                    type="input"
                    alerts={[]}
                    onChange={startDateHandler}
                    value={ ((query.startTime !== "") && (query.endTime !== "")) ? [query.startTime, query.endTime] : ""}
                    dateSeparator={" " + t('to') + " "}
                    range
                    dataPanelPosition="Bottom"
                    position="bottom-center"

                    /*readOnly={true}*/
                    hideOnScroll
                    inputClass={`width-100 my-1 ${classes.thisInput}`}


                    /*icon={
                        <Icons
                            iconName="icon-cancel  flex "
                            iconClass={`toastIcon cursor-pointer  mx-2`}
                            onClick={() => {
                                /!*startDateHandler()*!/
                                console.log("done")
                                setQuery({...query,
                                    startTime: null,
                                    endTime: null
                                })
                            } }
                        />
                    }*/


                />

                <div className={`row jc-between ai-center my-1 px-7 fs-0-8 width-100`}>
                    <span className={`fs-0-9 ml-1`}>{t("History.ascendingByTime")}</span>
                    <ToggleSwitch
                        onchange={ () => setQuery(prevState => {return {
                            ...prevState,
                            ascendingByTime: !prevState.ascendingByTime
                        }}) }
                        checked={!query?.ascendingByTime}/>
                </div>

                <div className={`width-100 py-2 rounded-8 column jc-between ai-center`} ref={scrollRef}>
                    <div className={`width-100 ${classes.header}`}>
                        <div className={`width-90 row jc-between ai-center m-auto py-2`}>
                            <span className={`font-weight-bold fs-02`}>{t("History.withdrawHistory")}</span>
                            <div className={`row mr-1 text-gray fs-0-8`}>
                                {periodTextHandler()}
                            </div>
                        </div>


                    </div>
                    <div className={`${classes.container} width-100 column jc-center ai-center`}>

                        <div className={`${classes.subHeader} width-90 text-start fs-01 font-weight-bold`}>
                            <Trans
                                i18nKey="page"
                                values={{
                                    page: (query?.offset / query?.limit) + 1,
                                }}
                            />
                        </div>

                        {content()}
                    </div>
                </div>






            </div>

            <div className={`width-100 px-5 py-2 row jc-between ai-center`}>
                <Button
                    buttonClass={`${classes.thisButton} width-30`}
                    buttonTitle={t('first')}
                    disabled={pagination.page === 1}
                    type="button"
                    onClick={firstPage}
                />
                <Button
                    buttonClass={`${classes.thisButton} width-30`}
                    buttonTitle={t('prev')}
                    disabled={pagination.page === 1}
                    type="button"
                    onClick={prevPage}
                />
                <Button
                    buttonClass={`${classes.thisButton} width-30`}
                    buttonTitle={t('next')}
                    disabled={pagination.isLastPage}
                    type="button"
                    onClick={nextPage}
                />
            </div>
        </div>

    );
};

export default WithdrawHistory;
