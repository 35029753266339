import axios, {axiosPrivate} from "./axios";

export const reserveOrder = (params, token) => {

    return axios.post(`/api/v1/reserve`, null, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',

        },
        params: params
    });

}
export const finalizeOrder = (reserveNumber, params, token) => {

    return axios.post(`/api/v1/finalize/${reserveNumber}`, params, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',

        },
    });

}


export const sendWithdrawReq = async (withdrawRequestData, token) => {
    return axios.post(`/api/v1/withdraws`, withdrawRequestData,
        {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',

            }
        }
    )
}

export const getCurrencies = () => {
    /*    const params = new URLSearchParams();
        params.append('refresh', refresh);
        params.append('url', url);*/
    return axios.get(`/api/v1/dashboard`, null ,{
        /*data: params,*/
        headers: {

        },
    });
}


export const cancelWithdrawReq = async (withdrawId, token) => {
    return axios.get(`/api/v1/withdraws/${withdrawId}/cancel`,
        {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',

            }
        }
    )

}