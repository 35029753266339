import React from 'react';
import classes from './Message.module.css';
import moment from "moment-jalaali";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import Icon from "../../../../../../components/Icon/Icons";
import useGlobal from "../../../../../../Hooks/useGlobal";




const Message = ({data}) => {
    const {t} = useTranslation();
    const user = data?.user_id === data?.from_id
    const BASE_URL = window.env.REACT_APP_API_BASE_URL;

    const {global, setGlobal} = useGlobal();



    const copyToClipboard = (e, value) => {
        e.preventDefault();
        navigator.clipboard.writeText(value)
        toast.success(t("copy"));
    }

    const selectClickHandler = () => {
        setGlobal({
            ...global,
            activeActionSheet: {
                menu: false,
                select: true,
            },
            selectType: "sampleImg",
            ["kyc_sampleImg"]: data?.file1
        })
    }

    return (
        <div className={`${classes.container} width-100 flex ai-center ${ user ? 'js-start' : 'jc-end'} my-05`}>
            <div className={`${classes.content} column rounded-8 px-4 py-1 ${ user ? 'bg-light-blue-alpha' : 'bg-light-gray'}`}>
                {data?.file1 && <div className={`position-relative`}>
                    <img src={`${data?.file1}`} className={`${classes.file} width-100 rounded-8 mb-2`} alt=""/>
                    <Icon
                        iconName={`icon-resize-full fs-01 flex cursor-pointer hover-text`}
                        iconClass={`${classes.icon} ${user ? classes.leftIcon : classes.rightIcon} position-absolute flex jc-center ai-center`}
                        onClick={()=>selectClickHandler()}
                    />
                </div>}
                <span className={`fs-01`} onDoubleClick={(e) => copyToClipboard(e, data?.text)}>{data?.text}</span>
                <span className={`fs-0-9 mt-1 ${user ? 'text-end' : 'text-start'}`}>{moment(data?.created_at).format("jYYYY/jMM/jDD , HH:mm:ss")}</span>
            </div>
        </div>
    );
};

export default Message;
