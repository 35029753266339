import axios, {axiosPrivate} from "./axios";

export const requestOTP = (mobile, forgetPass = false) => {
    return axios.post('/api/v1/request-otp', {
        mobile: mobile,
        forget_pass: forgetPass,
    })
};

export const validateOTP = (mobile, otp) => {


    const params = new URLSearchParams();
    params.append('client_id', "admin-cli");
    params.append('mobile', mobile);
    params.append('otp', otp);

    return axios.post('/api/v1/validate-otp', params)
};

export const register = (params, token) => {
    return axios.post(`/api/v1/register`,params, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
}

export const updateProfile = (params, token) => {
    return axios.put(`/api/v1/user/update`,params, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
}


export const updatePassword = (params, token) => {
    return axios.post(`/api/v1/user/update-password`,params, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
}
export const forgetPassword = (params) => {
    return axios.post(`/api/v1/forget-pass`,params, {
        headers: {
            'Accept': 'application/json',
        }
    });
}

export const createAccount = (params, token) => {
    return axios.post(`/api/v1/bank-accounts`,params, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
}

export const deleteAccount = (id, token) => {
    return axios.delete(`/api/v1/bank-accounts/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
}

export const sendMessage = (params) => {
    return axiosPrivate.post(`/api/v1/messages/send`, params)
}

export const sendFile = (file, text, token) => {
    const data = new FormData();
    if(text.length > 0) data.append('text', text);
    data.append('file1', file);
    /*return axios.post(`/api/v1/messages/send`, data)*/
    return axios.post(`/api/v1/messages/send`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
};

export const login = (mobile, password) => {
    const params = new URLSearchParams();
    params.append('mobile', mobile);
    params.append('password', password);

    return axios.post('/api/v1/login', params)
};

export const logout = (token) => {
    return axios.get('/api/v1/logout', {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
};


export const revokeTokens = (params) => {
    return axiosPrivate.get(`/api/v1/revoke-tokens`, params)
}

/*export const sendKYCRequest = (files, token) => {
    const data = new FormData();


    console.log("files", files)

    for (const key in files) {
        if (files[key]?.preview) {
            data.append(key, files[key].preview);
        }
    }

    /!*files?.accept_form_img !== "" && data.append('accept_form_img', files?.accept_form_img);
    files?.selfie_img !== "" && data.append('selfie_img', files?.selfie_img);
    files?.id_card_img !== "" && data.append('id_card_img', files?.id_card_img);*!/

    /!*return axiosPrivate.post(`/api/v1/kyc/request`, data)*!/

    return axios.post(`/api/v1/kyc/request`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
};*/

/*export const sendKYCRequest = async (files, token) => {
    const data = new FormData();

    for (const key in files) {
        if (files[key]?.preview) {
            const response = await fetch(files[key].preview);
            const blob = await response.blob();
            const fileName = files[key].path
            data.append(key, blob, fileName);
        }
    }

    return axios.post(`/api/v1/kyc/request`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
};*/

export const sendKYCRequest = async (files, token) => {
    const data = new FormData();

    for (const key in files) {
        if (files[key] instanceof File) {
            data.append(key, files[key], files[key].name);
        } else {
            console.warn(`Invalid file for key ${key}`);
        }
    }

    return axios.post(`/api/v1/kyc/request`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
};

