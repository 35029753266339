import React from 'react';
import classes from './AccountsList.module.css'
import ScrollBar from "../../../../../../components/ScrollBar";
import {useTranslation} from "react-i18next";
import Account from "../Account/Account";
import {useGetBankAccounts} from "../../../../../../query";
import {images} from "../../../../../../assets/images/images";
import Button from "../../../../../../components/Button/Button";

const AccountsList = () => {

    const {t} = useTranslation();
    const {data, isLoading, error, refetch} = useGetBankAccounts()

    const content = () => {
        if (isLoading) {
            return <div className={`width-100 column jc-center ai-center height-100`}>
                <div className={`${classes.loading} width-100 rounded-8 my-1`}/>
                <div className={`${classes.loading} width-100 rounded-8 my-1`}/>
                <div className={`${classes.loading} width-100 rounded-8 my-1`}/>
                <div className={`${classes.loading} width-100 rounded-8 my-1`}/>
            </div>
        }
        if (error) {
            return <div className={`width-100 column jc-center ai-center height-100 my-10`}>

                <Button
                    type="submit"
                    buttonClass={`${classes.thisButton} cursor-pointer rounded-100-p mb-1 px-6`}
                    buttonTitle={t('retry')}
                    onClick={()=>refetch()}
                />
                <span className={`mt-1`}>{t("error")}</span>
            </div>
        }
        if (data?.length <= 0) {
            return <div className={`width-100 column jc-center ai-center height-100 py-10`}>
                <img src={images.file} className={`${classes.image} mb-1`} alt=""/>

                <span className={`mt-1`}>{t("noData")}</span>
            </div>
        }
        return data?.map((data , index) => <Account data={data} index={index} key={index} />)


    }

    return (
        <div className={`${classes.container} width-86 height-100 rounded-8 mt-4 column jc-between ai-center`}>
            <ScrollBar>
                <div className={`column`}>

                    {content()}


                </div>
            </ScrollBar>
        </div>
    );
};

export default AccountsList;
