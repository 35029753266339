import {useQuery} from "@tanstack/react-query";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import useAuth from "../../Hooks/useAuth";
import axios from "../../api/axios";

export const useGetBankAccounts = (type, config = {}) => {

    const axiosPrivate =  useAxiosPrivate()
    const {auth} = useAuth();

    let params = {}

    if (type) {
        params = {
            ...params,
            ...(type) && {type},
        }
    }

    return useQuery(
        ['accounts', auth?.mobile, params],
        async () => {
            const {data} = await axiosPrivate.get(`/api/v1/bank-accounts`, {params})
            return data?.data;
        },
        config);
}

