import React from 'react';
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import useGlobal from "../../../../../../Hooks/useGlobal";
import HistorySizeCard from "../HistorySizeCard/HistorySizeCard";

const HistorySize = ({all}) => {

    const {t} = useTranslation();
    const location = useLocation()

    const pathname = location?.pathname

    const {global, setGlobal} = useGlobal();

    const size = [10, 20, 30, 40, 50]

    const content = () => {

        return <>
            {size.map(data => <HistorySizeCard data={data}/>)}
        </>
    }

    return (
        content()
    );
};

export default HistorySize;
