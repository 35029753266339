import React from 'react';
import classes from './Gateways.module.css';
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";
import {useGetGateways} from "../../../../../../query";
import NetworkCard from "../NetworkCard/NetworkCard";
import GatewaysCard from "../GatewaysCard/GatewaysCard";
import OnChainWithdraw from "../../../../Pages/Withdrawal/Module/OnChainWithdraw/OnChainWithdraw";
import OffChainWithdraw from "../../../../Pages/Withdrawal/Module/OffChainWithdraw/OffChainWithdraw";

const Gateways = ({type}) => {

    const {t} = useTranslation();

    const {global, setGlobal} = useGlobal();

    const currency = (type === "deposit_network" && global?.selected_deposit_coin) || (type === "withdrawal_network" && global?.selected_withdrawal_coin)

    const {data, isLoading, error} = useGetGateways(currency)

    const content = () => {

        if ( type === "withdrawal_network" && !global?.selected_withdrawal_coin) {
            return <span className={`my-10`}>{t("Deposit.selectNetwork")}</span>
        }
        if ( type === "deposit_network" && !global?.selected_deposit_coin) {
            return <span className={`my-10`}>{t("Deposit.selectNetwork")}</span>
        }
        if (isLoading) {
            return <span className={`my-10`}>{t("loading")}</span>
        }
        if (error) {
            return <span className={`my-10`}>{t("error")}</span>
        }
        if (data?.length <= 0) {
            return <span className={`my-10`}>{t("noData")}</span>
        }

        if (type === "deposit_network") {
            return Object.keys(data)
                .filter((f) => data[f]?.depositAllowed)
                .map(key => <GatewaysCard data={data[key]} type={type} key={key}/>)
        }
        if (type === "withdrawal_network") {
            return Object.keys(data)
                .filter((f) => data[f]?.withdrawAllowed)
                .map(key => <GatewaysCard data={data[key]} type={type} key={key}/>)
        }


    }

    return (
        content()
    );
};

export default Gateways;
