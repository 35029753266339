import {useQuery} from "@tanstack/react-query";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";

export const useGetKycParams = () => {

    const axiosPrivate =  useAxiosPrivate()

    return useQuery(
        ['kyc-params'],
        async () => {
            const {data} = await axiosPrivate.get(`/api/v1/kyc/params`)
            return data?.data?.params;
        });
}