import React, {useEffect} from 'react';
import classes from './Coin.module.css'
import {useTranslation} from "react-i18next";
import {BN, ratePrint} from "../../../../../../utils/utils";
import {useGetDashboard} from "../../../../../../query";
import {useNavigate} from "react-router-dom";
import * as RoutesName from "../../../../../../Routes/routes";
import {Markets} from "../../../../../../Routes/routes";
import useGlobal from "../../../../../../Hooks/useGlobal";
import {isMobile} from "react-device-detect";


const Coin = ({data, rate, sourceRate}) => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const {global} = useGlobal();

    const useByeAndSellPrice = window.env.REACT_APP_USE_BUY_AND_SELL_PRICE.toLowerCase() === "true"

    const rateHandler = () => {

        if (!data?.rates) return <span>---</span>
        const newObject = [];

        Object.keys(data?.rates).forEach((key) => {

            newObject.push( <div className={`row `} key={key}>

                <span className={`ml-1 text-gray fs-0-9`}>{key}</span>

                <span>{data?.symbol === key ? 1 : data?.rates[key] !== null ? ratePrint(data?.rates[key], global?.currencies[key]?.precision) : "---"}

                </span>
            </div>)

        });

        return newObject;
    }


    const rateHandlerWithBuyAndSellPrice= () => {

        return <>
            <div className={`row width-100 jc-end`}>
                <span className={`text-gray fs-0-9 width-35`}>{t("buy")}</span>
                <span className={`width-65 row jc-end`}>
                    <span className={`text-gray fs-0-9 ${isMobile ? 'pl-1' : 'pl-025'}`}>{sourceRate}</span>
                    <span>{rate?.buyPrice !== null ? ratePrint(rate?.buyPrice, global?.currencies[sourceRate]?.precision) : "---"}</span>
                </span>
            </div>
            <div className={`row width-100 jc-end`}>
                <span className={`text-gray fs-0-9 width-35`}>{t("sell")}</span>
                <span className={`width-65 row jc-end`}>
                    <span className={`text-gray fs-0-9 ${isMobile ? 'pl-1' : 'pl-025'}`}>{sourceRate}</span>
                    <span>{rate?.sellPrice !== null ? ratePrint(rate?.sellPrice, global?.currencies[sourceRate]?.precision) : "---"}</span>
                </span>
            </div>

        </>

        /*if (!data?.rates) return <span>---</span>


        const newObject = [];

        Object.keys(data?.rates).forEach((key) => {

            newObject.push( <div className={`row `} key={key}>

                <span className={`ml-1 text-gray fs-0-9`}>{key}</span>

                <span>{data?.symbol === key ? 1 : data?.rates[key] !== null ? ratePrint(data?.rates[key], global?.currencies[key]?.precision) : "---"}

                </span>
            </div>)*/


    };

    const navigateHandler = () => {

        /*setAuth({
            ...auth,
            backLocation: location?.search
        })*/
        navigate(RoutesName.Markets + data?.symbol)
    }

    return (
        <div className={`width-90 py-2 row jc-between ai-center ${classes.container}`} onClick={() => navigateHandler()}>
           {/* <div className={`${classes.circle} width-23 flex jc-start ai-center`}
                 style={{backgroundImage: `url("${data?.image_url}")`}}

            >

            </div>*/}

            <img src={data?.icon} alt="" className={`${classes.circle} ${classes.icon} width-23 flex jc-start ai-center`}/>

            <div className={`${classes.rectangle} width-76 row jc-between ai-center`}>
                <div className={`width-40 column jc-center ai-start`}>
                    <span className={`${classes.name}`}>{data?.alias}</span>
                    <span className={``}>{data?.symbol}</span>
                </div>

                <div className={`width-60 column jc-center ai-end`}>

                    {
                        useByeAndSellPrice ? rateHandlerWithBuyAndSellPrice() : rateHandler()
                    }



                    {/*<span className={` ${classes.rate} fs-01`}>{data?.rate?.rate?.toLocaleString()}</span>*/}
                    {/*<span className={` text-gray`}>{data?.rate?.rate ? t("currency."+ data?.rate?.destSymbol) : "- - -"}</span>*/}
                    {/*<span className={` text-gray `}>{}</span>*/}

                </div>
            </div>
        </div>
    );
};

export default Coin;