import React, {useState} from 'react';
import classes from './SwapHistoryTable.module.css';
import {Trans, useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";
import Date from "../../../../../../components/Date/Date";
import moment from "moment-jalaali";
import {BN, ratePrint} from "../../../../../../utils/utils";

const SwapHistoryTable = ({data}) => {

    const {t} = useTranslation();

    const {global} = useGlobal();

    const [isOpen, setIsOpen] = useState(false);

    const sourceSymbolPrecision= global?.currencies[data?.sourceSymbol]?.precision || 0
    const destSymbolPrecision= global?.currencies[data?.destSymbol]?.precision || 0

    return (
        <div className={`width-90 py-2 columns jc-between ai-center ${classes.container}`} >

            <div className={`width-100 column jc-between ai-center ${classes.rectangle}`} onClick={()=>setIsOpen(prevState => !prevState)}>
                <div className={`width-100 row jc-between ai-center`}>
                    <div className={`row`}>
                        <span><Date date={data?.reserveDate}/></span>
                        <span className={`${classes.spacing}`}/>
                        <span className={``}>{moment.utc(data?.reserveDate).local().format("HH:mm:ss")}</span>
                    </div>
                    <span></span>
                </div>
                <div className={`width-100 row jc-between ai-center`}>
                    <span>{t("payAmount")}</span>
                    <div className={`direction-ltr row text-red`} style={{alignItems:"baseline"}}>
                        <span className={`fs-03`}>{ new BN(data?.sourceAmount).decimalPlaces(sourceSymbolPrecision).toFormat()}</span>
                        <span className={`${classes.spacing}`}/>
                        <span>{data?.sourceSymbol}</span>
                    </div>
                </div>
                <div className={`width-100 row jc-between ai-center`}>
                    <span>{t("receiveAmount")}</span>
                    <div className={`direction-ltr row text-green`} style={{alignItems:"baseline"}}>
                        <span className={`fs-03`}>{ new BN(data?.reservedDestAmount).decimalPlaces(destSymbolPrecision).toFormat()}</span>
                        <span className={`${classes.spacing}`}/>
                        <span>{data?.destSymbol}</span>
                    </div>
                </div>
                <div className={`width-100 row jc-between ai-center`}>
                    <span>{t("calculatedRate")}</span>
                    <div className={`direction-ltr row`} style={{alignItems:"baseline"}}>
                        <span className={``}>{ ratePrint(data?.rate, destSymbolPrecision)}</span>
                        <span className={`${classes.spacing}`}/>
                        <span>{data?.destSymbol}</span>
                    </div>
                </div>

            </div>

            {isOpen && <div className={`width-100 column jc-start ai-start mt-2 ${classes.rectangle}`}>
                <div className={`width-100 row jc-between ai-center`}>
                    <span>
                        <Trans
                            i18nKey="History.calculatedRateDescription"
                            values={{
                                sourceSymbol: data?.sourceSymbol,
                                calculatedRate: ratePrint(data?.rate, destSymbolPrecision),
                                destSymbol: data?.destSymbol,
                            }}
                        />
                    </span>

                </div>
            </div>}
        </div>
    );
};

export default SwapHistoryTable;
