import React from 'react';
import classes from './ParamReport.module.css';
import Icons from "../../../../../../components/Icon/Icons";
import {images} from "../../../../../../assets/images/images";
import {useTranslation} from "react-i18next";
import Icon from "../../../../../../components/Icon/Icons";
import useGlobal from "../../../../../../Hooks/useGlobal";

const ParamReport = ({data, status, image}) => {

    const {t} = useTranslation();
    const {global, setGlobal} = useGlobal();

    const selectClickHandler = () => {
        setGlobal({
            ...global,
            activeActionSheet: {
                menu: false,
                select: true,
            },
            selectType: "sampleImg",
            ["kyc_sampleImg"]: image
        })
    }

    return (
        <div className={`column width-100 my-2`}>
            <div className={`row jc-start ai-center ${status === "Accepted" ? 'text-green' : 'text-red'}`}>
                <Icons iconName={`${status === "Accepted" ? 'icon-ok' : 'icon-cancel'} flex jc-center ai-center fs-02`}/>
                <span className={`${classes.title} font-weight-bold fs-01`}>{data?.name_fa}</span>
            </div>

            <div className={`column jc-start ai-start ${classes.nav}`}>
                <span className={`mt-1 mb-2`}>{ (status !== undefined) ? t("AuthenticationHint." + status) : "- - -"}</span>

                { image ?
                    <div className={`position-relative`}>
                        <img src={image} alt="" className={`width-75 rounded-8 ${classes.image}`}/>
                        <Icon
                            iconName={`icon-resize-full fs-01 flex cursor-pointer hover-text`}
                            iconClass={`${classes.thisIcon} position-absolute flex jc-center ai-center`}
                            onClick={()=>selectClickHandler()}
                        />
                    </div>
                    :
                    <div className={`${classes.imageWrapper} width-75 rounded-8 flex jc-center ai-center`}>
                        <img src={images.ETC} alt="" className={`${classes.blankImage}`} />
                    </div>
                }
            </div>
        </div>
    );
};

export default ParamReport;
