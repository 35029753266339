import React, {useState} from 'react';
import classes from './UploadDocuments.module.css';
import {useNavigate} from "react-router-dom";
import useAuth from "../../../../../../Hooks/useAuth";
import ScrollBar from "../../../../../../components/ScrollBar";
import {useGetKycParams, useGetKycStatus} from "../../../../../../query";
import {images} from "../../../../../../assets/images/images";
import Button from "../../../../../../components/Button/Button";
import KycParam from "../KycParam/KycParam";
import toast from "react-hot-toast";
import {Trans, useTranslation} from "react-i18next";
import {sendKYCRequest} from "../../../../../../api/auth";
import * as RoutesName from "../../../../../../Routes/routes";
import i18next from "i18next";

const UploadDocuments = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const [sending, setSending] = useState(false);

    const {auth} = useAuth();

    const {data: statusData, isLoading: statusIsLoading, error: statusError, refetch} = useGetKycStatus()

    const {data: paramsData, isLoading: paramsIsLoading, error: paramsError} = useGetKycParams()

    const [documents, setDocuments] = useState({});

    const isSubmitDisabled = () => {
        const emptyFields = [];

        if (paramsData) {
            Object.keys(paramsData).forEach((key) => {
                if (statusData.admin_text[key] !== "Accepted" && (!documents[key] || !documents[key].path)) {
                    emptyFields.push(paramsData[key].name_fa);
                }
            });
        }

        return emptyFields.length > 0 ? emptyFields : false;
    };



    const handleSubmit = () => {
        const emptyFieldNames = isSubmitDisabled();
        if (emptyFieldNames) {
            const fieldsMessage = emptyFieldNames.join(" ، ");
            toast.error(
                <Trans
                    i18nKey="Authentication.emptyField"
                    values={{
                        emptyFieldNames: fieldsMessage,
                    }}
                />
            );
            return true;
        }
        return false;
    };


    const sendDocumentHandler = async () => {

        const hasError = handleSubmit();

        if (hasError) {
            return;
        }

        setSending(true)

        sendKYCRequest(documents, auth?.token)
            .then(async (res) => {
                refetch()
                navigate(RoutesName.KYCStatus)
            }).catch(err => {
            if (i18next.exists('ErrorMessages.'+ err?.response?.data?.message)) {
                toast.error(t("ErrorMessages."+ err?.response?.data?.message))
            }
            else {
                toast.error(t("serverError"))
            }
            setSending(false)
        }).finally(() => {

        })
    };

    const content = () => {
        if (paramsIsLoading) {
            return <div className={`width-86 column jc-center ai-center height-100`}>
                <div className={`${classes.loading} width-100 rounded-8 my-1`}/>
                <div className={`${classes.loading} width-100 rounded-8 my-1`}/>
                <div className={`${classes.loading} width-100 rounded-8 my-1`}/>
            </div>
        }
        if (paramsError) {
            return <div className={`width-86 column jc-center ai-center height-100 my-10`}>
                <span className={`mt-1`}>{t("error")}</span>
            </div>
        }
        if (paramsData?.length <= 0) {
            return <div className={`width-86 column jc-center ai-center height-100 py-10`}>
                <img src={images.file} className={`${classes.image} mb-5`} alt=""/>

                <span className={`mt-1`}>{t("noData")}</span>
            </div>
        }

        if (sending) {
            return <div className={`width-86 column jc-center ai-center height-100 py-10`}>
                <img src={images.upload} className={`${classes.image} mb-5`} alt=""/>

                <span className={`mt-1`}>{t("sending")}</span>
            </div>
        }

        return <>
            <div className={`width-86 column jc-center ai-center`}>
                {
                    Object.keys(paramsData)
                        .filter((field) => statusData?.admin_text[field] !== "Accepted")
                        .map((field, index) => (
                            <KycParam
                                data={paramsData[field]}
                                documents={documents}
                                setDocuments={setDocuments}
                                key={index}
                            />
                        ))
                }


            </div>
        </>
    }


    return (
        <ScrollBar>
            <div className={`${classes.container} column jc-between ai-center py-3 px-5`}>
                {content()}
                <Button
                    type="submit"
                    buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mt-2 mb-2`}
                    buttonTitle={t('submit')}
                    disabled={!paramsData || sending}
                    onClick={sendDocumentHandler}
                />

            </div>
        </ScrollBar>
    );
};

export default UploadDocuments;
