import React from 'react';
import classes from './HistorySizeCard.module.css'
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";
import {useLocation} from "react-router-dom";

const HistorySizeCard = ({data}) => {

    const {t} = useTranslation();

    const location = useLocation()
    const pathname = location?.pathname

    const {global, setGlobal} = useGlobal();

    const selectedCoinHandler = (data) => {

        let newGlobal = {...global}

        newGlobal = {
            ...newGlobal,
            activeActionSheet: {
                menu: false,
                select: false,
            },
            selectType: null,
        }

        if ((pathname?.includes("history")) && (pathname?.includes("transactions"))) {
            newGlobal = {
                ...newGlobal,
                ["selected_" + "transactions_" + global?.selectType]: data
            }
        }
        if ((pathname?.includes("history")) && (pathname?.includes("deposit"))) {
            newGlobal = {
                ...newGlobal,

                ["selected_" + "deposit_" + global?.selectType]: data
            }
        }
        if ((pathname?.includes("history")) && (pathname?.includes("withdraw"))) {
            newGlobal = {
                ...newGlobal,

                ["selected_" + "withdraw_" + global?.selectType]: data
            }
        }
        if ((pathname?.includes("history")) && (pathname?.includes("swap"))) {
            newGlobal = {
                ...newGlobal,

                ["selected_" + "swap_" + global?.selectType]: data
            }
        }


        setGlobal(newGlobal)

    }

    return (
        <div className={`${classes.asset} row jc-between ai-center py-1 my-2 px-3 rounded-8`}
             onClick={()=>selectedCoinHandler(data)}
        >
            <span>{data}</span>
        </div>
    );
};

export default HistorySizeCard;
