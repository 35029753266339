import {useQuery} from "@tanstack/react-query";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import useAuth from "../../Hooks/useAuth";

export const useGetDepositHistory = (query) => {

    const axiosPrivate =  useAxiosPrivate()
    const {auth} = useAuth();

    return useQuery(
        ['depositHistory', auth?.mobile, query.currency, query.endTime, query.startTime, query.limit, query.offset, query.ascendingByTime],
        async () => {

            const {data} = await axiosPrivate.post(`/api/v1/history/deposit`, query)
            return data?.data;
        },
        {
            retry: 1,
            staleTime: 5000,
            refetchInterval: 10000,
        });
}
