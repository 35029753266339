import {createContext, useEffect, useState} from "react";
import jwtDecode from "jwt-decode";

const AuthContext = createContext({});

export const AuthProvider = ({children}) => {
    const [auth, setAuth] = useState({
        mobile: null,
        InitialToken: null,
        token: null,
        wallets: {},
        isExpired: false,
    });

    useEffect(() => {
        if (auth.mobile) {
            localStorage.setItem("mobile", auth?.mobile);
        }
        if (auth.InitialToken) {
            localStorage.setItem("InitialToken", auth?.InitialToken);
        }
        if (auth.token) {
            localStorage.setItem("token", auth?.token);
        }
    }, [auth]);

    useEffect(() => {
        const setExpirationTimeout = () => {
            if (auth.token) {
                const jwt = jwtDecode(auth.token);
                const expirationTime = jwt.exp * 1000;
                const currentTime = Date.now();
                const timeUntilExpiration = expirationTime - currentTime;
                if (timeUntilExpiration <= 0) {
                    setAuth(prevAuth => ({ ...prevAuth, isExpired: true }));
                } else {
                    const timeoutId = setTimeout(() => {
                        setAuth(prevAuth => ({ ...prevAuth, isExpired: true }));
                    }, timeUntilExpiration + 2000);
                    return timeoutId;
                }
            }
        };
        const timeoutId = setExpirationTimeout();
        return () => clearTimeout(timeoutId);
    }, [auth.token]);

    return (
        <AuthContext.Provider value={{auth, setAuth}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
