import React from 'react';
import classes from './History.module.css';
import ScrollBar from "../../../../components/ScrollBar";
import {NavLink, Navigate, Route, Routes} from "react-router-dom";
import * as RoutesName from "../../../../Routes/routes";
import {useTranslation} from "react-i18next";
import TransactionsHistory from "./Module/TransactionsHistory/TransactionsHistory";
import DepositHistory from "./Module/DepositHistory/DepositHistory";
import WithdrawHistory from "./Module/WithdrawHistory/WithdrawHistory";
import SwapHistory from "./Module/SwapHistory/SwapHistory";


const History = () => {

    const {t} = useTranslation();

    return (
        <div className={` width-86 m-auto height-100 rounded-8 pt-3 pb-5 column jc-center ai-center`}>
            <ScrollBar>



                <div className={`row ${classes.container} width-100 my-1`}>
                   <NavLink
                       className={({ isActive }) => isActive ? `${classes.active} ${classes.item}` : `${classes.item}`}
                        to={RoutesName.TransactionsHistory}>
                       {t("History.transactions")}
                    </NavLink>
                   <NavLink
                       className={({ isActive }) => isActive ? `${classes.active} ${classes.item}` : `${classes.item}`}
                        to={RoutesName.DepositHistory}>
                       {t("History.deposit")}
                    </NavLink>
                   <NavLink
                       className={({ isActive }) => isActive ? `${classes.active} ${classes.item}` : `${classes.item}`}
                        to={RoutesName.WithdrawHistory}>
                       {t("History.withdraw")}
                    </NavLink>
                   <NavLink
                       className={({ isActive }) => isActive ? `${classes.active} ${classes.item}` : `${classes.item}`}
                        to={RoutesName.SwapHistory}>
                       {t("History.swap")}
                    </NavLink>

                </div>



                <div className={`${classes.content} width-100`}>
                    <Routes>
                        <Route path={RoutesName.History} element={<Navigate to={{pathname: `${RoutesName.TransactionsHistory}`}} replace/>}/>
                        <Route path={RoutesName.TransactionsHistoryRelative} element={<TransactionsHistory/>}/>
                        <Route path={RoutesName.DepositHistoryRelative} element={<DepositHistory/>}/>
                        <Route path={RoutesName.WithdrawHistoryRelative} element={<WithdrawHistory/>}/>
                        <Route path={RoutesName.SwapHistoryRelative} element={<SwapHistory/>}/>
                    </Routes>
                </div>


            </ScrollBar>
        </div>
    );
};

export default History;
