import React from 'react';
import classes from './KycStatus.module.css'
import {useNavigate} from "react-router-dom";
import {useGetKycParams, useGetKycStatus} from "../../../../../../query";
import {images} from "../../../../../../assets/images/images";
import {useTranslation} from "react-i18next";
import Button from "../../../../../../components/Button/Button";
import ScrollBar from "../../../../../../components/ScrollBar";
import * as RoutesName from "../../../../../../Routes/routes";
import Loading from "../../../../../../components/Loading/Loading";
import ParamReport from "../ParamReport/ParamReport";

const KycStatus = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const {data: statusData, isLoading: statusIsLoading, error: statusError, refetch} = useGetKycStatus()
    const {data: paramsData, isLoading: paramsIsLoading, error: paramsError} = useGetKycParams()

    const briefStatusSection = () => {
        return <div className={`${classes[statusData?.kyc_status]} ${classes.statusBox} row jc-between ai-center width-86 rounded-8`}>

            <span className={`width-80`}>{t("KYCStatus."+ statusData?.kyc_status)}</span>
            { statusData?.kyc_status !== "NoKYC" && <img className={`${classes.statusImg}`} src={images[statusData?.kyc_status]} alt={statusData?.kyc_status}/>}
        </div>
    }
    const reportStatusSection = () => {

        if ((statusData?.kyc_status === "Pending") || (statusData?.kyc_status === "NoKYC")) return false;

        return <div className={`width-86 my-2`}>
            <div className={`mb-2 width-100 column jc-start`}>
                <span className={`font-weight-bold`}>{t("Authentication.adminText")}:</span>
                <span>{statusData?.admin_text?.description ? statusData?.admin_text?.description : "---"}</span>
            </div>
            <div className={`rounded-8 flex jc-center ai-center ${classes.noteBox} px-1 py-1 mt-1 width-100`}>
                <span>{t("Authentication.errorReport")}</span>
            </div>
            <div className={`column width-100 mb-3`}>
                {
                    Object.keys(paramsData)?.map((field, index) => <ParamReport data={paramsData[field]} status={statusData?.admin_text[field]} image={statusData[field]} key={index}/>)
                }
            </div>
        </div>

    }

    const buttonSection = () => {

        if (statusData?.kyc_status === "Pending" || statusData?.kyc_status === "NoKYC" || statusData?.kyc_status === "Blocked") return false;

        return <Button
            type="button"
            buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-2`}
            buttonTitle={statusData?.kyc_status === "Rejected" ? t('retry') : t('home')}
            onClick={()=>navigate(statusData?.kyc_status === "Rejected" ? RoutesName.UploadDocuments : RoutesName.Home )}
        />
    }


    if  (paramsIsLoading || statusIsLoading) {
        return <Loading type="text"/>
    }
    if  (paramsError || statusError) {
        return <div className={`width-100 column jc-center ai-center height-100`}>
            <Button
                type="submit"
                buttonClass={`${classes.thisButton} cursor-pointer rounded-100-p mb-1 px-6`}
                buttonTitle={t('retry')}
                onClick={()=>refetch()}
            />
            <span className={`mt-1`}>{t("error")}</span>
        </div>
    }

    return (
        <ScrollBar>
            <div className={`${classes.container} column jc-between ai-center pb-3 pt-3 px-5`} >
                {briefStatusSection()}
                {reportStatusSection()}
                {buttonSection()}
            </div>
        </ScrollBar>
    );
};

export default KycStatus;
